<template>
  <div>
    <div class="tt">
      <el-button size="mini" plain type="primary" @click="back">返回</el-button>
    </div>
    <iframe class="box"  :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    this.url = 'https://1xsub.zzyzwl.com/weixintuwenbjq_jb51/index.html'
    console.log(34,this.url)
  },
  methods: {
    back() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.tt {
  padding: 10px 0;
  width: 1000px;
  margin: 0 auto;
}
.box {
  width: 100%;
  height: calc(100vh - 40px);
}
</style>