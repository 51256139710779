<template>
  <div>
    <div class="nav">
      <div @click="nav(1)" :class="index == 1 ? 'active' : ''">图片</div>
      <div @click="nav(2)" :class="index == 2 ? 'active' : ''">视频</div>
      <div @click="nav(3)" :class="index == 3 ? 'active' : ''">学术</div>
    </div>
    <div class="list">
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
      <div class="box" @click="goDetail">
        <img class="img" src="../assets/img/textbg.png" alt="">
        <div class="tt">零基础学会会计 7天速成班</div>
        <div class="tt1">零基础学会会</div>
        
      </div>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :page-size="10"
        :current-page="1"
        layout="prev, pager, next"
        :total="1"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: 1
    }
  },
  methods: {
    nav(i) {
      this.index = i
    },
    goDetail() {
      this.$router.push('/textDetail')
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  display: flex;
  margin-bottom: 20px;
  div {
    font-size: 16px;
    color: #525252;
    margin-right: 43px;
    padding-bottom: 4px;
    border-bottom: 3px solid #fff;
    cursor: pointer;
  }
  .active {
      color: #538eff;
      border-bottom: 3px solid #538eff;
    }
}
.list {
  display: flex;
  flex-wrap: wrap;
  .box {
    width: 221px;
    height: 260px;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11);
    color: #525252;
    font-size: 14px;
    margin: 0 40px 30px 0;
    cursor: pointer;
    .img {
      width: 100%;
      height: 150px;
      display: block;
    }
    .tt {
      font-size: 16px;
      margin: 10px;
      font-weight: 700;
      
    }
    .tt1 {
      margin: 0 10px 10px 10px;
    }
    .tt2 {
      margin:0 10px;
    }
  }
  .box:nth-child(4n) {
    margin: 0 0 30px 0;
  }
}
.pagina {
  text-align: center;
  padding: 20px;
}
</style>