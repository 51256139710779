<template>
  <div>
    <div class="detail">
      <div class="box">
        <div class="lt_img">
          <img
            :src="item.resources_url_host + item.resources_cover_pic"
            alt=""
          />
        </div>
        <div class="rt_box">
          <div class="tt">{{ item.resources_name }}</div>
          <div class="tt1">课程简介</div>
          <div class="text">
            {{ item.resources_content }}
          </div>
          <div class="btn">
            <div
              class="btn_lt"
              @click="down(item.resources_url_host + item.resources_url)"
            >
              <img class="img1" src="../assets/img/xiazai2.png" alt="" />
              <div>下载资源</div>
            </div>
            <div
              class="btn_lt"
              @click="shoucang"
              v-if="item.is_collection == 0"
            >
              <img class="img2" src="../assets/img/xingxing.png" alt="" />
              <div>收藏</div>
            </div>
            <div class="btn_lt" v-else>
              <img class="img2" src="../assets/img/xingxing.png" alt="" />
              <div>已收藏</div>
            </div>
          </div>
        </div>
      </div>
      <div class="box2">
        <div class="lt">
          <!-- 音频 -->
          <div v-if="resources_type == 3">
            <video width="520" height="100" controls>
              <source :src="resources_url" />
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <!-- 视频 -->
          <div v-if="resources_type == 4">
            <video width="520" height="340" controls>
              <source :src="resources_url" />
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <!-- 文本 -->
          <div v-if="resources_type == 5 && resources_url.slice(-3) == 'pdf'">
            <iframe
              width="640"
              height="400"
              :src="resources_url"
              frameborder="0"
            ></iframe>
          </div>
          <div v-if="resources_type == 5 && resources_url.slice(-3) != 'pdf'">
            <iframe
              width="640"
              height="400"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                resources_url
              "
              frameborder="0"
            ></iframe>
          </div>
          <div v-if="resources_type == 6">
            <div>请前往考试系统考试</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 收藏弹出框 -->
    <el-dialog
      title="收藏"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="120px" size="small">
        <el-form-item label="资源分类: ">
          <el-select
            class="ipt"
            v-model="form.tag_nos"
            multiple
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in indexList"
              :key="item.tag_no"
              :label="item.tag_name"
              :value="item.tag_no"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注: ">
          <el-input
            class="ipt"
            type="textarea"
            :rows="2"
            v-model="form.remarks"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add_btn">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {},
      form: {
        resources_no: '',
        resources_name: '',
        tag_nos: [],
        remarks: '',
      },
      dialogVisible: false,
      resources_type: 1,
      resources_url: '',
      indexList: [],
    }
  },
  mounted() {
    this.item = JSON.parse(this.$route.query.item) || ''
    console.log(this.item)
    if (this.item) {
      this.resources_type = this.item.resources_type
      this.resources_url = this.item.resources_url_host + this.item.resources_url
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
      this.form = {
        resources_no: '',
        resources_name: '',
        tag_nos: [],
        remarks: '',
      }
    },
    // 标签列表
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/resources/resources/resourcestagindex',
        {
          params: {
            limit: 100,
            page: 1,
          },
        }
      )

      this.indexList = res.data.list
    },
    // 下载资源
    down(url) {
      window.open(url)
    },
    // 收藏弹出框
    shoucang() {
      this.dialogVisible = true
      this.form.resources_no = this.item.resources_no
      this.form.resources_name = this.item.resources_name
      this.index()
    },
    // 收藏
    async add_btn() {
      this.form.tag_nos = this.form.tag_nos.join()
      const { data: res } = await this.$http.post(
        'index.php/api/resources/resources/addcollection',
        this.form
      )
      if (res.code != 1) {
        this.form.tag_nos = this.form.tag_nos
          ? this.form.tag_nos.split(',')
          : []
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.dialogVisible = false
      this.form = {
        resources_no: '',
        resources_name: '',
        tag_nos: [],
        remarks: '',
      }
    },
  },
}
</script>

<style lang="less" scoped>
.detail {
  width: 100%;
  min-height: calc(100vh - 110px);
  overflow: hidden;
  .box {
    width: 100%;
    height: 250px;
    background-color: #fff;
    margin: 28px auto;
    display: flex;
    align-items: center;
    padding: 0 70px 0 35px;
    .lt_img {
      width: 324px;
      min-width: 324px;
      height: 182px;
      margin-right: 26px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rt_box {
      flex: 1;
      .tt {
        font-size: 24px;
        color: #444444;
        margin-bottom: 16px;
      }
      .tt1 {
        width: 100%;
        font-size: 14px;
        color: #444444;
        padding-left: 5px;
        border-left: 2px solid #538eff;
      }
      .text {
        font-size: 14px;
        color: #444444;
        margin: 5px 0 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .btn {
        display: flex;
        .btn_lt {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 181px;
          height: 43px;
          background: #538eff;
          border-radius: 3px;
          font-size: 20px;
          color: #ffffff;
          margin-right: 34px;
          cursor: pointer;
          .img1 {
            width: 19px;
            height: 20px;
            display: block;
            margin-right: 10px;
          }
          .img2 {
            width: 25px;
            height: 24px;
            display: block;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .box2 {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 100px;
    .lt {
      width: 880px;
      // height: 400px;
      background-color: #fff;
      margin-bottom: 25px;
    }
    .rt {
      .box3 {
        width: 400px;
        padding: 12px 12px 30px;
        background-color: #fff;
        margin-bottom: 25px;
        .img_box {
          width: 376px;
          height: 213px;
          // margin: 11px auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .tt {
          font-size: 24px;
          color: #333333;
          margin: 37px 0 27px 0;
        }
        .text {
          font-size: 16px;
          color: #666666;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          // margin-bottom: 5px;
        }
      }
    }
  }
}
.ipt {
  width: 400px;
}
</style>