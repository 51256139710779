<template>
  <div>
    <div class="tt">
      练习名称：<span>{{ exercise_name }}</span>
    </div>
    <div class="tt">
      所属老师：<span>{{ teacher_ame }}</span>
    </div>
    <div class="tt">
      发布时间：<span>{{ add_date }}</span>
    </div>
    <div class="box">
      <div class="text" v-if="flow == 'radio'">
        一. 单选题<span>（{{ list_question.length }}道）</span>
      </div>
      <div class="text" v-if="flow == 'checkbox'">
        一. 多选题<span>（{{ list_question.length }}道）</span>
      </div>
      <div class="text" v-if="flow == 'decide'">
        一. 判断题<span>（{{ list_question.length }}道）</span>
      </div>
      <div class="text" v-if="flow == 'listen'">
        一. 听打题<span>（{{ list_question.length }}道）</span>
      </div>
      <div class="text" v-if="flow == 'answer'">
        一. 问答题<span>（{{ list_question.length }}道）</span>
      </div>
      <div v-if="flow == 'listen'" class="listen">
        <div class="list1" v-for="(item, i) in list_question" :key="i">
          <div class="title1">{{ i + 1 }}.{{ item.title }}</div>
          <div v-for="(it, e) in item.listAnswer" :key="e" class="boxlisten">
            <!-- 音频 -->
            <div v-if="item.questions_type == 'listen'">
              <video width="400" height="85" controls>
                <source :src="it.audio" />
                您的浏览器不支持 video 标签。
              </video>
            </div>
            <div>
              <div class="zuoda">作答：</div>
              <el-input
                class="ipt"
                type="textarea"
                :autosize="{ minRows: 12 }"
                placeholder="请输入内容"
                v-model="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
      </div>
      <div v-if="flow == 'answer'" class="listen">
        <div class="list2" v-for="(item, i) in list_question" :key="i">
          <div class="title2" v-html="item.title"></div>
          <div>
            <div class="zuoda">作答：</div>
            <el-input
              class="ipt2"
              type="textarea"
              :autosize="{ minRows: 12 }"
              placeholder="请输入内容"
              v-model="textarea2"
            >
            </el-input>
          </div>
        </div>
      </div>
      <div v-if="flow != 'answer' && flow != 'listen'">
        <div class="list" v-for="(item, i) in list_question" :key="i">
          <div class="title">{{ i + 1 }}.{{ item.title }}</div>
          <div class="radio_box" v-for="(it, e) in item.listAnswer" :key="e">
            <!-- 单选题 -->
            <el-radio
              v-if="item.questions_type == 'radio'"
              @change="radioChange(i, e, it)"
              v-model="it.check"
              :label="e"
              >{{ it.title }}
            </el-radio>
            <!-- 判断  -->
            <el-radio
              v-if="item.questions_type == 'decide'"
              @change="decideChange(i, e, it)"
              v-model="it.check"
              :label="e"
              >{{ it.title }}
            </el-radio>
            <!-- 多选  -->
            <el-checkbox
              v-if="item.questions_type == 'checkbox'"
              @change="checkboxChange(i, e, it)"
              v-model="it.check"
              >{{ it.title }}</el-checkbox
            >
          </div>
          <div class="list_btn">
            <div class="btn_rt1" v-if="item.right_answer_format">
              <div>正确答案：</div>
              <div v-for="(it, e) in item.listAnswer" :key="e">
                <span
                  v-if="
                    item.right_answer_format.indexOf(it.question_answer_id) !=
                    -1
                  "
                  >{{ it.title }}</span
                >
              </div>
            </div>
            <div
              v-if="answer.length > 0 && (flow == 'radio' || flow == 'decide')"
            >
              <div
                :class="
                  item.right_answer_format == answer[i].question_id
                    ? 'btn_lt'
                    : 'btn_lt'
                "
                v-for="(it, e) in item.listAnswer"
                :key="e"
              >
                <div v-if="e == answer[i].check">考生答案：</div>
                <div v-if="e == answer[i].check">
                  <span>{{ it.title }}</span>
                </div>
              </div>
            </div>
            <div v-if="answer.length > 0 && flow == 'checkbox'">
              <div
                :class="
                  item.right_answer_format == answer[i].question_id
                    ? 'btn_rt1'
                    : 'btn_rt1'
                "
              >
                <div>考生答案：</div>
                <div>
                  <span>{{ answer[i].title }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn" v-if="exercise_type == 2">
      <el-button size="small" type="primary" @click="tijiao"
        >提交练习</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      exercise_name: '',
      teacher_ame: '',
      add_date: '',
      list_question: [],
      // 考生答案
      answer: [],
      flow: '',
      textarea: '',
      textarea2: '',
      exercise_type: ''
    }
  },
  mounted() {
    this.exercise_no = this.$route.query.exercise_no
    this.date = this.$route.query.date
    this.index()
  },
  methods: {
    // 学生-查看练习详情 /index.php/api/exercise/exercise/studentexercisedetail
    async index() {
      const { data: res } = await this.$http.get(
        'index.php/api/exercise/exercise/studentexercisedetail',
        {
          params: {
            exercise_no: this.exercise_no,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.exercise_name = res.data.exercise_name
      this.teacher_ame = res.data.teacher_ame
      this.add_date = res.data.add_date
      this.exercise_type = res.data.exercise_type
      this.exercise_content = JSON.parse(res.data.exercise_content)
      console.log(222, this.exercise_content)
      this.flow = this.exercise_content[0].list_question[0].questions_type
      // 如果有考生答案如下
      if (res.data.my_answer.toString()) {
        if(res.data.my_answer.answer_c) {
          this.answer = res.data.my_answer.answer_c
        }else {
          this.answer = JSON.parse(res.data.my_answer.answer)
        }
        
        console.log(444, this.answer)
        this.list_question = this.exercise_content[0].list_question
        if (this.flow == 'decide') {
          this.list_question.forEach((it) => {
            this.answer.forEach((it1) => {
              if (it.question_id == it1.question_id) {
                it.listAnswer.forEach((it2) => {
                  it2.check = ''
                })
                it.listAnswer[it1.check].check = it1.check
              }
            })
          })
        }
        if (this.flow == 'radio') {
          this.list_question.forEach((it) => {
            this.answer.forEach((it1) => {
              if (it.question_id == it1.question_id) {
                it.listAnswer.forEach((it2) => {
                  it2.check = ''
                })
                it.listAnswer[it1.check].check = it1.check
              }
            })
          })
        }
        if (this.flow == 'checkbox') {
          this.list_question = this.exercise_content[0].list_question
          this.list_question.forEach((it) => {
            this.answer.forEach((it1) => {
              if (it.question_id == it1.question_id) {
                it.listAnswer.forEach((it2) => {
                  it2.check = false
                  it1.question_answer_id.forEach((e) => {
                    if (it2.question_answer_id == e) {
                      it2.check = true
                    }
                  })
                })
              }
            })
          })
        }
        if (this.flow == 'listen') {
          this.textarea = this.answer
        }
        if (this.flow == 'answer') {
          this.textarea2 = this.answer
        }

        console.log(333, this.list_question)
      } else {
        this.list_question = this.exercise_content[0].list_question
        console.log(3333, this.list_question)
      }
    },
    // 单选框变化
    radioChange(i, e, it1) {
      this.list_question[i].listAnswer.forEach((it) => {
        it.check = ''
      })
      it1.check = e
      this.$set(this.list_question[i].listAnswer, e, it1)
    },
    // 多选框变化

    checkboxChange(i, e, it1) {
      console.log(i, e)
      this.$set(this.list_question[i].listAnswer, e, it1)
      console.log(this.list_question)
    },
    // 判断题变化
    decideChange(i, e, it1) {
      this.list_question[i].listAnswer.forEach((it) => {
        it.check = ''
      })
      it1.check = e
      this.$set(this.list_question[i].listAnswer, e, it1)
    },
    // 学生-提交练习 /index.php/api/exercise/exercise/studentexercisecommit
    async tijiao() {
      let aa = []

      if (this.flow == 'radio') {
        this.list_question.forEach((it) => {
          it.listAnswer.forEach((it1) => {
            if (it1.check !== '') {
              aa.push({
                check: it1.check,
                question_id: it.question_id,
              })
            }
          })
        })
      }
      if (this.flow == 'decide') {
        this.list_question.forEach((it) => {
          it.listAnswer.forEach((it1) => {
            if (it1.check !== '') {
              aa.push({
                check: it1.check,
                title: it1.title,
                question_id: it.question_id,
              })
            }
          })
        })
      }
      if (this.flow == 'checkbox') {
        this.list_question.forEach((it) => {
          let bb = []
          let cc = []
          it.listAnswer.forEach((it1) => {
            if (it1.check) {
              bb.push(it1.question_answer_id)
              cc.push(it1.title)
            }
          })
          if (bb.length <= 0)
            return this.$message.error('您没有作答完，无法提交')
          aa.push({
            question_answer_id: bb,
            question_id: it.question_id,
            title: cc,
          })
        })
        console.log(1, aa)
      }
      if (this.flow == 'listen') {
        aa = this.textarea
      }
      if (this.flow == 'answer') {
        aa = this.textarea2
      }
      if (aa.length < this.list_question.length)
        return this.$message.error('您没有作答完，无法提交')
      const { data: res } = await this.$http.post(
        'index.php/api/exercise/exercise/studentexercisecommit',
        {
          exercise_no: this.exercise_no,
          answer: JSON.stringify(aa),
          use_time: (new Date().getTime = this.date),
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.index()
    },
  },
}
</script>

<style lang="less" scoped>
.tt {
  font-size: 16px;
  color: #535454;
  margin-bottom: 20px;
  span {
    color: #98989e;
  }
}
.box {
  width: 100%;
  height: 499px;
  background: #ffffff;
  // border: 2px solid #f1f4f8;
  // box-shadow: 11px 0px 0px 0px rgba(162, 162, 162, 0.15);
  overflow-y: auto;
  padding: 20px;
  .text {
    font-size: 16px;
    color: #000000;
    span {
      color: #999999;
    }
  }
  .listen {
    .list1 {
      overflow: hidden;
      .title1 {
        font-size: 16px;
        color: #000000;
        margin: 25px 0 20px;
      }
      .zuoda {
        color: #999999;
        font-size: 16px;
        margin-bottom: 10px;
      }
      .boxlisten {
        display: flex;
        justify-content: space-between;
        .ipt {
          width: 500px;
        }
      }
    }
    .list2 {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      .title2 {
        width: 500px;
        height: 400px;
        overflow-y: auto;
      }
      .zuoda {
        color: #999999;
        font-size: 16px;
        margin-bottom: 10px;

      }
      .ipt2 {
        width: 440px;
      }
    }
  }
  .list {
    overflow: hidden;
    .title {
      font-size: 16px;
      color: #000000;
      margin: 25px 0 20px;
    }
    .radio_box {
      width: 100%;
      height: 58px;
      background: #f1f4f8;
      margin-bottom: 18px;
      font-size: 16px;
      line-height: 58px;
      padding: 0 20px;
    }
    .list_btn {
      display: flex;
      font-size: 16px;
      color: #000000;
      .btn_lt {
        display: flex;
        padding-left: 8px;
        border-left: 6px solid #0699f8;
        margin: 0 80px 0 20px;
      }
      .btn_rt {
        display: flex;
        padding-left: 8px;
        border-left: 6px solid #f56c6c;
      }
      .btn_rt1 {
        display: flex;
        padding-left: 8px;
        border-left: 6px solid #03d071;
      }
    }
  }
}
.btn {
  margin: 30px auto;
  text-align: center;
}
</style>